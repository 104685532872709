import type { NextPage } from "next";
import { useContext } from "react";
import { useSelector } from "react-redux";
import nextAuth from "../next-routes/next-auth";
import GenericSetHead from "../src/components/shared-components/GenericSetHead";
import { BrandContext } from "../src/contexts/BrandContext";
import { BrandState } from "../src/redux/reducers/brand.reducer";
import {
  brandConfigSelector,
  brandSelector,
} from "../src/redux/selectors/app.selectors";
import wrapper from "../src/redux/store";
import { BrandConfigKeys } from "../src/utils/enums";
import GatedEntryPageView from "../src/views/gated-entry/GatedEntryPageView";

export const getServerSideProps = wrapper.getServerSideProps((store) =>
  nextAuth(store, undefined, {
    noAuth: true,
  })
);

const GatedEntryPage: NextPage = () => {
  const { brand, configEntities }: BrandState = useSelector(brandSelector);
  const { shareable_image_url, favicon_image_url } = useContext(BrandContext);

  if (!brand) {
    return null;
  }

  return (
    <>
      <GenericSetHead
        title={`${brand?.title}` ?? "Gated Entry"}
        siteName={brand?.title ?? "Gated Entry"}
        metadata={[
          {
            property: "description",
            content: brandConfigSelector(
              configEntities,
              BrandConfigKeys.WELCOME
            )?.value.subheading,
          },
          {
            property: "image",
            content: shareable_image_url || "",
          },
          {
            property: "url",
            content: brand?.domain,
          },
          {
            property: "icon",
            content: favicon_image_url || "",
          },
        ]}
      />
      <GatedEntryPageView />
    </>
  );
};

export default GatedEntryPage;
