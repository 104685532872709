import { useRouter } from "next/router";
import React, { useEffect, useState } from "react";
import { v5 as uuidv5 } from "uuid";
import NextRouteConfig from "../../../next-routes/NextRouteConfig";
import TopRoundedSpinner from "../../components/shared-components/Spinner/TopRoundedSpinner";
import BrandButton from "../../components/shared-components/custom-components/BrandButton";
import Input from "../../components/shared-components/input/Input/Input";
import {
  ENV_GATED_ENABLED,
  ENV_NAMESPACE,
  ENV_PASSWORD,
} from "../../constants/secret";
import { webStorageService } from "../../services/WebStorageService";

interface GatedEntryPageViewProps {}

const GatedEntryPageView: React.FC<GatedEntryPageViewProps> = () => {
  const router = useRouter();
  const [loading, setLoading] = useState<boolean>(true);
  const [password, setPassword] = useState<string>("");
  const [isError, setIsError] = useState<boolean>(false);

  const isGatedPasswordRequired = ENV_GATED_ENABLED;

  useEffect(() => {
    if (isGatedPasswordRequired !== "true") {
      webStorageService.setGatedToken(
        uuidv5(ENV_PASSWORD as string, ENV_NAMESPACE as string)
      );
      router.replace(NextRouteConfig.login);
    } else {
      setLoading(false);
    }
  }, [router, isGatedPasswordRequired]);

  const handleClick = async () => {
    if (
      uuidv5(password as string, ENV_NAMESPACE as string) ===
      uuidv5(ENV_PASSWORD as string, ENV_NAMESPACE as string)
    ) {
      await webStorageService.setGatedToken(
        uuidv5(password as string, ENV_NAMESPACE as string)
      );
      await router.replace(NextRouteConfig.login);
    } else {
      setIsError(true);
    }
  };

  return (
    <>
      {loading ? (
        <div className="flex items-center justify-center w-full h-full">
          <TopRoundedSpinner />
        </div>
      ) : (
        <div className="flex items-center justify-center h-full space-y-5">
          <div className="flex flex-col items-center justify-center space-y-6 w-72">
            <div className="text-4xl font-primary">- Welcome -</div>
            <div className="w-full">
              <Input
                value={password}
                type="password"
                label="Password"
                error={isError ? "Incorrect Password! Try again." : undefined}
                placeholder="Enter the password"
                onChange={(e) => {
                  if (isError) {
                    setIsError(false);
                  }
                  setPassword(e.target.value);
                }}
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    handleClick();
                  }
                }}
              />
            </div>
            <div className="w-full">
              <BrandButton
                id="gated-entry"
                type="submit"
                className="w-full h-12 text-base font-semibold"
                theme="primary"
                onClick={handleClick}
              >
                Access
              </BrandButton>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default React.memo(GatedEntryPageView);
